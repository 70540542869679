<template>
  <div v-if="showForm">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">mdi-file-multiple</v-icon>
      <v-toolbar-title>
        {{ $t('common.workspace', locale) }}
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading" />
    <v-container v-else-if="editedItem">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Name"
                :label="$t('sysUser.name', locale)"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <color-picker-primary-secondary
                style="width: 100px;"
                :primaryColor="editedItem.PrimaryColor"
                :secondaryColor="editedItem.SecondaryColor"
                :onChangeColor="handleChangeColors"
              />
            </v-col>
            <!--v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.RenameFolders"
                :label="$t('workspace.renameFolders', locale)"
              />
            </v-col-->
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-autocomplete
                v-model="editedItem.Languages"
                :items="languages"
                outlined
                chips
                small-chips
                :label="$t('workspace.languages', locale)"
                multiple
                itemText="NormalName"
                itemValue="ID"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="editedItem.ScheduleTypes"
                :items="scheduleTypes"
                outlined
                :label="$t('workspace.scheduleType', locale)"
                itemText="Name"
                itemValue="ID"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-checkbox
                v-model="editedItem.AllowReservations"
                :label="$t('booking.allowReservations', locale)"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <select-sys-users-permissions
                area="booking"
                :workspaceID="editedItem.ID"
                :onChangeUser="handleChangeUser"
              />
              <!--select-sys-users
                area="dfiles"
                :workspaceID="editedItem.ID"
                :onChangeUser="handleChangeUser"
              /-->
            </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-expansion-panels focusable>
            <acttiv-form
              :workspaceID="editedItem.ID"
              :onChangeData="handleChangeActtiv"
            />
            <dviewcontent-form
              :workspaceID="editedItem.ID"
              :onChangeData="handleChangeDviewcontent"
            />

            <notifications-form
              v-if="editedItem.AllowReservations"
              :workspaceID="editedItem.ID"
              :onChangeData="handleChangeNotifications"
            />
            <form-fields-default-form 
              :formFields="editedItem.FormFieldsDefault"
              :onChangeData="handleChangeFormFieldDefault"
              :locale="locale"
              :allowReservations="editedItem.AllowReservations"
              :workspaceID="editedItem.ID"
            />
            <custom-fields-info-form
              :workspaceID="editedItem.ID"
              :onChangeData="handleCustomFieldsInfoForm"
              :locale="locale"
              :languages="editedItem.Languages"
            />
            <categories-form 
              :categories="editedItem.Categories"
              :onChangeData="handleChangeCategories"
              :locale="locale"
              :workspaceID="editedItem.ID"
              :languages="editedItem.Languages"
            />

            <attendance-form 
              :categories="editedItem.Categories"
              :onChangeData="handleChangeAttendance"
              :locale="locale"
              :workspaceID="editedItem.ID"
              :languages="editedItem.Languages"
            />
          </v-expansion-panels>
        </v-col>
          </v-row>
    </v-container>
    <action-buttons
      v-if="editedItem"
      :id="editedItem.ID"
      :onDeleteItem="handleDelete"
      :onSaveItem="handleSave"
      :onClose="onClose"
      :locale="locale"
    />
  </div>
</template>
<script>
const defaultItem = {
  Name: '',
  Users: [],
  Languages: [ 'es', 'en' ],
}

import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import SelectSysUsersPermissions from '@/components/SelectSysUsersPermissions'
import ActionButtons from '@/components/ActionButtons'
import ColorPickerPrimarySecondary from '@/components/ColorPickerPrimarySecondary'
import ActtivForm from './ActtivForm'
import DviewcontentForm from './DviewcontentForm'
import NotificationsForm from './NotificationsForm'
import FormFieldsDefaultForm from './FormFieldsDefaultForm'
import CustomFieldsInfoForm from './customFieldsInfoForm/Index'
import CategoriesForm from './categoriesForm/Index'
import AttendanceForm from './attendanceForm/Index'
export default{
  name: 'WorkspaceForm',
  components: {
    ActionButtons,
    //SelectSysUsers,
    SelectSysUsersPermissions,
    ColorPickerPrimarySecondary,
    ActtivForm,
    DviewcontentForm,
    NotificationsForm,
    FormFieldsDefaultForm,
    CustomFieldsInfoForm,
    CategoriesForm,
    AttendanceForm,
  },
  props: {
    editedID: {
      type: String,
      default: null
    },
    showForm: {
      type: Boolean,
      default: false
    },
    onDelete: {
      type: Function,
      required: true
    },
    onSave: {
      type: Function,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
  },
  data () {
    return {
      loading: false,
      editedItem: null
    }
  },
  computed: {
    ...mapState('app',['locale']),
    languages () {
      return utils.getAllLanguages()
    },
    scheduleTypes () {
      return [
        {
          ID: null,
          Name: 'ALL',
        },
        {
          ID: 'specific',
          Name: 'specific',
        },
        {
          ID: 'recurrence',
          Name: 'recurrence',
        },
      ]
    },
  },
  watch: {
    showForm (v) {
      if (v) {
        if(this.editedID) this.handleGetData()
        else this.editedItem = defaultItem
      }
    },
    editedID (v) {
      if (this.showForm) {
        if (v) this.handleGetData()
        else this.editedItem = defaultItem

      }
    },
  },
  methods: {
    handleGetData () {
      this.loading = true
      api.getItem ('booking', 'v1/system/workspaces/', this.editedID)
        .then(response => {
          response.AllowReservations = response.AllowReservations === 1 || response.AllowReservations === true ? true : false
          response.Languages = response.Languages ? response.Languages.split(',') : []
          response.FormFieldsDefault = response.FormFieldsDefault ? JSON.parse(response.FormFieldsDefault) : null
          this.editedItem = response
          this.loading = false
        })
    },
    handleChangeUser (v) {
      this.editedItem.Users = v
    },
    handleChangeActtiv (v) {
      this.editedItem.Acttiv = v
    },
    handleChangeDviewcontent (v) {
      this.editedItem.Dviewcontent = v
    },
    handleChangeNotifications (v) {
      this.editedItem.Notifications = v
    },
    handleChangeFormFieldDefault (v) {
      this.editedItem.FormFieldsDefault = v
    },
    handleCustomFieldsInfoForm (v) {
      this.editedItem.CustomFieldsInfo = v
    },
    handleChangeCategories (v) {
      this.editedItem.Categories = v
    },
    handleChangeAttendance (v) {
      this.editedItem.Attendance = v
    },
    handleChangeColors (primaryColor, secondaryColor) {
      this.editedItem.PrimaryColor = primaryColor ? primaryColor : null
      this.editedItem.SecondaryColor = secondaryColor ? secondaryColor : null
    },
    handleSave () {
      this.onSave(this.editedItem)
    },
    handleDelete () {
      this.onDelete(this.editedItem.ID)
    }
  }
}
</script>


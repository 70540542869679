const constants = {
  userPermission: [
    { 
      id: 'f4ea6694-bd3b-4223-869e-c72df6a1f92f',
      name: 'dashboard',
      icon: 'mdi-view-dashboard', 
      mainPath: 'dashboard',
      path: '/dtouch/dashboard', 
    },
    {
      id: '79e70894-9719-4f95-94af-b97bf81ce66a',
      name: 'personalization',
      icon: 'mdi-cellphone-settings',
      mainPath: 'personalization',
      path: '/dtouch/personalization',
    },
    {
      id: 'b72ca51a-b528-4a01-96e2-53f565d7413a',
      name: 'setting',
      icon: 'mdi-cellphone-cog',
      mainPath: 'setting',
      path: '/dtouch/setting',
    },
    {
      id: '2dee256a-19b3-4bfc-a477-245698ba9e8c',
      name: 'survey',
      icon: 'mdi-playlist-star',
      mainPath: 'survey',
      path: '/dtouch/survey',
    },
    {
      id: '7457dedc-48f0-48c6-b556-7ca1f702fdd3',
      name: 'dev',
      icon: 'mdi-dev-to',
      mainPath: 'dev',
      path: '/dtouch/dev',
    },
    {
      id: '4a9cb44f-1063-4e64-b336-ee44880a58d5',
      name: 'dishOrders',
      icon: 'mdi-food-variant',
      mainPath: 'dishOrders',
      path: '/dtouch/dish-orders',
    },
    {
      id: '38c96a74-0d55-46f0-8c5e-cd656fa58a28',
      name: 'welcomeForm',
      icon: 'mdi-form-select',
      mainPath: 'welcomeForm',
      path: '/dtouch/welcome-form',
    },

    {
      id: 'ee90cdb2-0d50-43e9-a250-1b73ecff172d',
      name: 'requestManager',
      icon: 'mdi-paper-roll',
      mainPath: 'requestManager',
      path: '/dtouch/request/manager',
    },
    {
      id: '8835f8e5-1bff-4c6b-b47e-42f33a09fd37',
      name: 'requestResult',
      icon: 'mdi-paper-roll-outline',
      mainPath: 'requestResult',
      path: '/dtouch/request/result',
    },
    {
      id: '983e9410-33f2-4333-a87f-6dd4fc590c87',
      name: 'issuesManager',
      icon: 'mdi-outdoor-lamp',
      mainPath: 'issuesManager',
      path: '/dtouch/issues/manager',
    },
    {
      id: '4a8391b8-ac49-4f7c-a9cc-8cac53ff9413',
      name: 'issuesResult',
      icon: 'mdi-outdoor-lamp',
      mainPath: 'issuesResult',
      path: '/dtouch/issues/result',
    },
  ],
  defaultMenu: {
    ID: 'new',
    Name: { es: 'Menu' },
  }
}

module.exports = constants

